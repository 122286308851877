.header {
    display: flex;
    position: fixed;
    z-index: 1;
    align-items: center;
    /* line-height: 2px; */
    width: 100%;
    height: 8vh;
    background-color: #E30536;
    color: white;
}

.container {
    margin-top: 8vh;
}

.footer {
    background-color: #262626;
    position: fixed;
    z-index: 1;
    bottom: 0px;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    padding: 10px 20px 10px 20px;
}

.filter {
    display: flex;
    flex-direction: column;
}

.zero-loading-state {
    display: flex;
    justify-content: center;
    background-color: white;

    padding-top: 100px;
}

.fullscreen-loader {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
    height: 0px;
    /* display: none; */
    overflow-y: scroll;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.supplier-table {
    margin-bottom: 0vh;
}

.ant-spin-dot-item {
  background-color: #E30536;
}

.supplier-table .ant-table-body {
    overflow-y: scroll;
    height: 79vh;
}

.ant-modal-content {
    width: 100%
}

.custom_modal {
    width: 90% !important;
}

.ant-descriptions-item-label {
    font-size: 14px;
    font-weight: 500;
}

.supplier-info .ant-descriptions-item > span {
    display: flex;
}

.ant-descriptions-item-colon::after {
    content: '';
}

.ant-table-pagination.ant-pagination {
    margin: 8px 8px 8px 0;
}

.supplyinfo .ant-card-head-wrapper{
    display: block;
}